








































































































































































































































































































































































































































































































































































































































































































































































































































































import { ref, Ref, computed, watch } from '@vue/composition-api';
import { User } from '@/@types/user.d';
import { Token } from '@/generated/graphql';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useUserState, useUserActions, useProgramActions } from '@/store';
import { fetchParticipants, saveKeywords } from '@/api/sponsorApi';
import PersonalWallet from '@/views/Portfolio/views/PersonalWallet.vue';
import axios from 'axios';
import OrganizationHeader from './OrganizationHeader.vue';
import { fetchTokens } from '../../../api/organizationApi';

export default {
  name: 'SponsorHome',

  components: {
    OrganizationHeader,
    PersonalWallet,
    // MAutoGenerate: () => import('@/components/molecules/m-auto-generate.vue'),
    AAutocomplete: () => import('@/components/atoms/AAutocomplete.vue')
  },
  // stakeholder name, sponsorLinkType, dateTime
  setup(_, ctx) {
    const sponsorRequestHeadersCompleted = [
      { text: 'Student Name', value: 'studentName', width: '20%' },
      { text: 'Program', value: 'programName', width: '20%' },
      { text: 'City', value: 'home', width: '10%' },
      { text: 'School', value: 'school', width: '15%' },
      { text: 'Culture', value: 'ethnicity', width: '20%' },
      { text: 'Stack Holder Name', value: 'stackHolderName', width: '20%' },
      { text: 'Sponsor Link', value: 'sponsorLink', width: '20%' },
      { text: 'Date Time', value: 'dateTime', width: '25%' },
      { text: 'Conditions', value: 'condition', width: '20%' }
      // { text: 'Actions', value: 'actions', sortable: false, width: '5%' }
    ];

    const sponsorRequestHeadersPending = [
      { text: 'Student Name', value: 'studentName', width: '20%' },
      { text: 'Program', value: 'programName', width: '20%' },
      { text: 'City', value: 'home', width: '10%' },
      { text: 'School', value: 'school', width: '15%' },
      { text: 'Culture', value: 'ethnicity', width: '20%' },
      { text: 'Date Time', value: 'dateTime', width: '25%' },
      { text: 'Actions', value: 'actions', sortable: false, width: '5%' }
    ];

    const { user }: any = useUserState(['user']);
    const tokens: Ref<Token[]> = ref([]);
    const { getTokens, getSponsorLinkByShareCode } = useUserActions([
      'getTokens',
      'getSponsorLinkByShareCode'
    ]);
    const {
      regenerateSponsorLink,
      toggleAutoSponsoringSwitch,
      saveSponsorshipCriteria,
      toggleSponsorLinkSwitch,
      toggleSponsorLinkStatus
    } = useProgramActions([
      'regenerateSponsorLink',
      'toggleAutoSponsoringSwitch',
      'saveSponsorshipCriteria',
      'toggleSponsorLinkSwitch',
      'toggleSponsorLinkStatus'
    ]);

    const colors = ['red', 'orange', 'blue', 'purple', 'pink', 'yellow'];
    const sponsorRoles = ref(false);
    const orgHeader = ref();
    const search: any = ref('');
    const isSearching = ref(false);
    const isSearching2 = ref(false);
    const tokensCount = ref(0);
    const isFetching = ref(false);
    const selectedParticipant: any = ref(null);
    const participantList: Ref<User[]> = ref([]);
    const searchText = ref([]);
    const searchText2 = ref([]);
    const selectedRequests = ref([]);
    const sponsorLoading = ref(false);
    const sponsorPendingRequests = ref([]);
    const sponsorCompletedRequests = ref([]);
    const isSaving = ref(false);
    const currentOrg: any = ref({});
    const sponsorDialog = ref(false);
    const sponsorState = ref(false);
    const transferState = ref(false);
    const skipForPendingRequest = ref(0);
    const limitForPendingRequest = ref(10);
    const skipForCompletedRequest = ref(0);
    const limitForCompletedRequest = ref(10);
    const totalSponsorCompletedRequests = ref(0);
    const totalSponsorPendingRequests = ref(0);
    const completedOptions: any = ref({});
    const pendingOptions: any = ref({});
    const isOffering = ref(false);
    const wallet = ref();
    const myWallet: any = ref({});
    const isGenerating = ref(false);
    const orgTokens = ref(0);
    const originalOwners: Ref<Pick<User, '_id' | 'firstName' | 'lastName'>[]> = ref([]);
    const isChanging = ref(false);
    const isSaveAutoSponsorCriteria = ref(false);
    const isCopySponsor = ref(false);
    const isGlobalAutoSponsoring = ref(false);
    const roles = ref();
    const rolesOptions = ref(['Employer', 'Student', 'Teacher', 'School', 'Parent']);
    const rolesSearch = ref();
    const hobbies = ref();
    const hobbiesOptions = ref([]);
    const hobbiesSearch = ref();
    const city = ref();
    const state = ref();
    const school = ref();
    const schoolDistrict = ref();
    const ageGroup = ref();
    const ageItems = ref(['1980-1990', '1991-2000', '2001-2010', '2011-2020', '2021-2030']);
    const isSaveCriteria = ref(false);
    const tokenDescDialog = ref(false);

    async function fetchOrgTokens(val) {
      isFetching.value = true;
      let response;
      if (val?._id) response = await fetchTokens(val?._id.toString());
      else response = await getTokens();
      orgTokens.value = response.organizationTokens;
      tokensCount.value = val?._id ? response?.balance : response?.personalWallet?.balance;
      myWallet.value = val?._id ? response : response?.personalWallet;
      isFetching.value = false;
    }

    const totalTokens = computed(() => {
      return orgTokens.value + tokensCount.value || 0;
    });

    const list = computed(() => {
      return participantList.value;
    });

    const walletList = computed(() => {
      return orgHeader.value?.walletList;
    });

    const totalSponsoredStds = computed(() => {
      return myWallet.value?.logs?.filter(l => l.studentId)?.length;
    });

    const sponsorName = item =>
      item.sponsorName ? item.sponsorName : `${item.firstName} ${item.lastName}`;

    // UI Management
    const modOriginalOwners = computed(() =>
      originalOwners.value.map(owner => ({
        ...owner,
        color: colors[Math.floor(Math.random() * (colors.length - 1))]
      }))
    );

    const isPersonalWallet = computed(() => {
      if (currentOrg?.value?.value === 'personal_wallet') {
        return true;
      }
      return false;
    });

    const isOwner = computed(() => {
      if (currentOrg.value?.created_by?.toString() === user.value?._id?.toString()) {
        return true;
      }
      if (isPersonalWallet.value) return true;
      return false;
    });

    const saveKeys = async () => {
      try {
        isSaving.value = true;
        const data: any = {
          organization_id: currentOrg?.value?._id.toString(),
          searchKeys: searchText2.value
        };
        await saveKeywords(data);
        isSaving.value = false;
      } catch (e) {
        isSaving.value = false;
      }
    };

    const searchPendingParticipants = async () => {
      const user_id = user?.value?._id?.toString();
      const org_id = currentOrg.value?._id?.toString();
      const type = isPersonalWallet.value ? 'personal' : 'organization';
      const participantType = 'pending';
      try {
        isSearching2.value = true;
        const result = await fetchParticipants(
          JSON.stringify(searchText.value),
          skipForPendingRequest.value,
          limitForPendingRequest.value,
          user_id,
          org_id,
          type,
          participantType
        );
        sponsorPendingRequests.value = [];
        sponsorPendingRequests.value = result.participantList;
        totalSponsorPendingRequests.value = result.total;
        if (searchText.value.length) await saveKeys();
        isSearching2.value = false;
      } catch (e) {
        isSearching2.value = false;
      }
    };

    const searchCompletedParticipants = async () => {
      const user_id = user?.value?._id?.toString();
      const org_id = currentOrg?.value?._id?.toString();
      // const type = 'personal';
      const type = isPersonalWallet.value ? 'personal' : 'organization';
      const participantType = 'completed';

      try {
        isSearching.value = true;
        const result = await fetchParticipants(
          JSON.stringify(searchText2.value),
          skipForCompletedRequest.value,
          limitForCompletedRequest.value,
          user_id,
          org_id,
          type,
          participantType
        );
        sponsorCompletedRequests.value = [];
        sponsorCompletedRequests.value = result.participantList;
        totalSponsorCompletedRequests.value = result.total;
        if (searchText2.value.length) await saveKeys();
        isSearching.value = false;
      } catch (e) {
        isSearching.value = false;
      }
    };

    const stakeholderList = computed(() => {
      return (
        orgHeader.value?.selectedOrganization?.users?.map(stk => ({
          ...stk,
          color: colors[Math.floor(Math.random() * (colors.length - 1))]
        })) || []
      );
    });
    const sponsorLink = ref();
    const shareCodeValue = ref();

    function getCriteriaLocalStorage() {
      searchText.value = [];
      let criteria: any;
      if (isPersonalWallet?.value) {
        criteria = localStorage.getItem(`Criteria_user_${user?.value?._id}`);
      } else {
        criteria = localStorage.getItem(`Criteria_org_${currentOrg?.value?._id}`);
      }
      if (criteria) {
        criteria = JSON.parse(criteria);
        for (let i = 0; i < Object.keys(criteria).length; i++) {
          const element = Object.keys(criteria)[i];
          if (!searchText.value.includes(criteria[element])) {
            searchText.value.push(criteria[element]);
          }
        }
      }
    }

    async function setOrganization(val) {
      let criteria;
      if (val?.value && val?.value === 'personal_wallet') {
        criteria = user?.value?.autoSponsorCriteria;

        roles.value = criteria?.roles;
        city.value = criteria?.city;
        state.value = criteria?.state;
        school.value = criteria?.school;
        schoolDistrict.value = criteria?.schoolDistrict;
        ageGroup.value = criteria?.age;
        hobbies.value = criteria?.hobbies;
        sponsorLink.value = `https://www.pilotcity.com/sponsor/${user.value?.shareCode}`;
        shareCodeValue.value = user?.value?.shareCode;
      } else {
        const currentUserVal = val?.users?.find(item => item?.user_id === user?.value?._id);
        criteria = currentUserVal?.autoSponsorCriteria;

        roles.value = criteria?.roles;
        city.value = criteria?.city;
        state.value = criteria?.state;
        school.value = criteria?.school;
        schoolDistrict.value = criteria?.schoolDistrict;
        ageGroup.value = criteria?.age;
        hobbies.value = criteria?.hobbies;
        sponsorLink.value = `https://www.pilotcity.com/sponsor/${currentUserVal?.shareCode}`;
        shareCodeValue.value = currentUserVal?.shareCode;
      }
      currentOrg.value = val;
      await getCriteriaLocalStorage();
      searchPendingParticipants();
      searchCompletedParticipants();
    }

    function redirectToPayment() {
      if (isPersonalWallet.value) {
        ctx.root.$router.push({ name: 'payment' });
      } else {
        ctx.root.$router.push({
          name: 'payment',
          query: { organization: true, orgId: currentOrg.value._id }
        });
      }
    }

    watch(pendingOptions, val => {
      if (val && totalSponsorPendingRequests.value >= 1) {
        limitForPendingRequest.value = val.itemsPerPage;
        skipForPendingRequest.value = (val.page - 1) * val.itemsPerPage;
        if (val.itemsPerPage === -1) {
          limitForPendingRequest.value = totalSponsorPendingRequests.value;
          skipForPendingRequest.value = 0;
        }
        searchPendingParticipants();
      }
    });
    watch(completedOptions, val => {
      if (val && totalSponsorCompletedRequests.value >= 1) {
        limitForCompletedRequest.value = val.itemsPerPage;
        skipForCompletedRequest.value = (val.page - 1) * val.itemsPerPage;
        if (val.itemsPerPage === -1) {
          limitForCompletedRequest.value = totalSponsorCompletedRequests.value;
          skipForCompletedRequest.value = 0;
        }
        searchCompletedParticipants();
      }
    });
    watch(searchText, val => {
      if (val?.length >= 1) {
        searchPendingParticipants();
      } else if (val?.length === 0) {
        if (isPersonalWallet?.value && localStorage.getItem(`Criteria_user_${user?.value?._id}`)) {
          localStorage.removeItem(`Criteria_user_${user?.value?._id}`);
          searchPendingParticipants();
        } else if (
          !isPersonalWallet?.value &&
          localStorage.getItem(`Criteria_org_${currentOrg?.value?._id}`)
        ) {
          localStorage.removeItem(`Criteria_org_${currentOrg?.value?._id}`);
          searchPendingParticipants();
        }
      }
    });
    watch(searchText2, val => {
      searchCompletedParticipants();
    });
    function openOfferDialog(item) {
      selectedParticipant.value = item;
      sponsorDialog.value = true;
    }

    async function offerSponsorship() {
      try {
        isOffering.value = true;
        const data = {
          student_id: selectedParticipant.value?.student_id?.toString(),
          organization_id: currentOrg.value?._id?.toString()
        };
        const res = await axios.post(
          `${process.env.VUE_APP_SPONSOR_SERVICE}/offer-sponsorship`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (res.data) {
          sponsorDialog.value = false;
          isOffering.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Sponsorship offered successfully',
            type: 'success',
            isShowSnackbar: true
          });
          searchPendingParticipants();
          searchCompletedParticipants();
        }
      } catch (error) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Something went wrong !!',
          type: 'error',
          isShowSnackbar: true
        });
        isOffering.value = false;
        sponsorDialog.value = false;
      }
    }

    async function updateOrg(val) {
      currentOrg.value = val;
      await orgHeader.value.getOrganizations();
    }

    const isLocalAutoSponsoring = ref(false);
    const localAutoSponsoring = computed({
      get: () => {
        if (isPersonalWallet?.value) {
          return user.value?.autoSponsoringAllowed;
        }
        return currentOrg?.value?.users?.find(
          u => u?.user_id?.toString() === user?.value?._id?.toString()
        )?.autoSponsoringAllowed;
      },
      set: newVal => {
        ctx.emit('input');
      }
    });

    async function toggleAutoSponsoringStatus() {
      isLocalAutoSponsoring.value = true;
      isGlobalAutoSponsoring.value = true;
      const payload = {
        document_id: isPersonalWallet?.value ? user?.value?._id : currentOrg?.value?._id,
        type: isPersonalWallet?.value ? 'User' : 'Organization',
        switch_type: 'local'
      };
      const res = await toggleAutoSponsoringSwitch(payload);
      if (res) {
        if (payload.type === 'Organization') {
          updateOrg(res);
          ctx.emit('update-org', res);
        }
        if (localAutoSponsoring.value) {
          isSaveAutoSponsorCriteria.value = true;
        }
      }
      isLocalAutoSponsoring.value = false;
      isGlobalAutoSponsoring.value = false;
    }
    async function toggleAutoSponsoringStatusNewStackHolders() {
      isLocalAutoSponsoring.value = true;
      isGlobalAutoSponsoring.value = true;
      const payload = {
        document_id: isPersonalWallet?.value ? user?.value?._id : currentOrg?.value?._id,
        type: isPersonalWallet?.value ? 'User' : 'Organization',
        switch_type: 'global'
      };
      const res = await toggleAutoSponsoringSwitch(payload);
      if (res) {
        if (payload.type === 'Organization') {
          updateOrg(res);
          ctx.emit('update-org', res);
        }
        // if (localAutoSponsoring.value) {
        //   isSaveAutoSponsorCriteria.value = true;
        // }
      }
      isLocalAutoSponsoring.value = false;
      isGlobalAutoSponsoring.value = false;
    }

    function setSchoolValue(e) {
      if (e.target) {
        school.value = e.target.value;
      } else if (e.name) {
        school.value = e.formatted_address;
      }
    }

    function setSchoolDistrictValue(e) {
      if (e.target) {
        schoolDistrict.value = e.target.value;
      } else if (e.name) {
        schoolDistrict.value = e.formatted_address;
      }
    }

    const criteriaData = computed(() => {
      return currentOrg?.value?.users?.find(
        u => u?.user_id?.toString() === user?.value?._id?.toString()
      ).autoSponsorCriteria;
    });

    const saveCriteria = async () => {
      isSaveCriteria.value = true;
      const criteria = {
        roles: roles.value,
        city: city.value,
        state: state.value,
        school: school.value,
        schoolDistrict: schoolDistrict.value,
        age: ageGroup.value,
        hobbies: hobbies.value
      };
      let _roles;
      let _city;
      let _state;
      let _school;
      let _schoolDistrict;
      let _age;

      if (roles.value.length !== 0) {
        _roles = `roles: ${roles.value}`;
      }
      if (city.value && city.value !== '') {
        _city = `city: ${city.value}`;
      }
      if (state.value && state.value !== '') {
        _state = `state: ${state.value}`;
      }
      if (school.value && school.value !== '') {
        _school = `school: ${school.value}`;
      }
      if (schoolDistrict.value && schoolDistrict.value !== '') {
        _schoolDistrict = `schoolDistrict: ${schoolDistrict.value}`;
      }
      if (ageGroup.value && ageGroup.value !== undefined) {
        _age = `age: ${ageGroup.value}`;
      }
      const arr = [_roles, _city, _state, _school, _schoolDistrict, _age];
      const filteredArr = arr.filter(item => item);
      searchText.value = filteredArr;

      const payload = {
        user_id: user?.value?._id?.toString(),
        criteria
      };
      if (isPersonalWallet?.value) {
        payload.type = 'User';
      } else {
        payload.type = 'Organization';
        payload.org_id = currentOrg?.value?._id?.toString();
      }
      const res = await saveSponsorshipCriteria(payload);
      if (res) {
        if (isPersonalWallet?.value) {
          localStorage.setItem(`Criteria_user_${user?.value?._id}`, JSON.stringify(filteredArr));
        } else {
          localStorage.setItem(
            `Criteria_org_${currentOrg?.value?._id}`,
            JSON.stringify(filteredArr)
          );
        }
        isSaveAutoSponsorCriteria.value = false;
        isSaveCriteria.value = false;
        await searchPendingParticipants();
      }
    };

    const organizationPermissions: any = ref(
      localStorage?.getItem('OrganizationPermissions')?.split(',') || []
    );

    const autoSponsoring = computed({
      get: () => {
        if (isPersonalWallet?.value) {
          return user.value?.isAutoSponsoring;
        }
        return currentOrg?.value?.isAutoSponsoring;
      },
      set: newVal => {
        ctx.emit('input');
      }
    });
    const copy = str => {
      navigator.clipboard.writeText(str);
      store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
        message: 'Link copied to clipboard',
        type: 'success',
        isShowSnackbar: true
      });
    };
    const isSharable = ref(false);
    const isSponsorLinkSharable = computed({
      get: () => {
        if (isPersonalWallet?.value) {
          return user.value?.sponsorLinkSharable;
        }
        return currentOrg?.value?.sponsorLinkSharable;
      },
      set: newVal => {
        ctx.emit('input');
      }
    });
    const sponsorLinkStatus = ref(false);

    async function toggleLink() {
      isSharable.value = true;
      const payload = {
        document_id: isPersonalWallet?.value ? user?.value?._id : currentOrg?.value?._id,
        type: isPersonalWallet?.value ? 'User' : 'Organization'
      };
      const res = await toggleSponsorLinkSwitch(payload);
      if (res) {
        if (payload.type === 'Organization') ctx.emit('update-org', res);
      }
      isSharable.value = false;
    }

    async function toggleLinkStatus(share_code, linkStatus) {
      isChanging.value = true;
      const status = linkStatus ? 'active' : 'inactive';
      const res: any = await toggleSponsorLinkStatus({ share_code, status });
      isChanging.value = false;
    }

    const handleCopySponsor = async () => {
      const response = await getSponsorLinkByShareCode({ share_code: shareCodeValue?.value });
      if (response && response?.status === 'active') {
        copy(sponsorLink.value);
        sponsorLinkStatus.value = true;
      } else if (response && response?.status === 'inactive') {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Your SponsorShipLink is not active yet.',
          type: 'error',
          isShowSnackbar: true
        });
        sponsorLinkStatus.value = false;
      }
      isCopySponsor.value = true;
    };

    async function regenerateLink() {
      try {
        let currentOrgUser;
        if (!isPersonalWallet?.value) {
          currentOrgUser = currentOrg?.value?.users?.find(
            u => u.user_id?.toString() === user.value?._id?.toString()
          );
        }
        const payload = {
          share_code: isPersonalWallet?.value ? user?.value?.shareCode : currentOrgUser?.shareCode,
          link_type: isPersonalWallet?.value ? 'personal' : 'organization'
        };
        isGenerating.value = true;
        const res: any = await regenerateSponsorLink(payload);
        if (res) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'SponsorLink Regenerated Successfully',
            type: 'success',
            isShowSnackbar: true
          });
          if (isPersonalWallet?.value) {
            res.value = 'personal_wallet';
          }
          await setOrganization(res);
          handleCopySponsor();
          // if (payload.link_type === 'organization') ctx.emit('update-org', res);
          isGenerating.value = false;
        }
      } catch (error) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Something went wrong',
          type: 'error',
          isShowSnackbar: true
        });
      }
    }

    return {
      user,
      isChanging,
      updateOrg,
      isGenerating,
      isOwner,
      sponsorDialog,
      openOfferDialog,
      modOriginalOwners,
      redirectToPayment,
      isPersonalWallet,
      totalSponsoredStds,
      sponsorRoles,
      currentOrg,
      setOrganization,
      tokensCount,
      fetchOrgTokens,
      sponsorPendingRequests,
      sponsorCompletedRequests,
      selectedParticipant,
      participantList,
      isFetching,
      isSearching,
      isSearching2,
      walletList,
      search,
      list,
      colors,
      stakeholderList,
      orgTokens,
      orgHeader,
      sponsorRequestHeadersCompleted,
      sponsorRequestHeadersPending,
      searchText,
      searchText2,
      selectedRequests,
      sponsorLoading,
      searchPendingParticipants,
      searchCompletedParticipants,
      skipForPendingRequest,
      limitForPendingRequest,
      skipForCompletedRequest,
      limitForCompletedRequest,
      completedOptions,
      pendingOptions,
      totalSponsorCompletedRequests,
      totalSponsorPendingRequests,
      sponsorState,
      transferState,
      sponsorName,
      wallet,
      offerSponsorship,
      isOffering,
      myWallet,
      totalTokens,
      tokenDescDialog,
      isLocalAutoSponsoring,
      localAutoSponsoring,
      toggleAutoSponsoringStatus,
      toggleAutoSponsoringStatusNewStackHolders,
      isSaveAutoSponsorCriteria,
      isCopySponsor,
      roles,
      rolesOptions,
      rolesSearch,
      hobbies,
      hobbiesOptions,
      hobbiesSearch,
      city,
      state,
      school,
      setSchoolValue,
      schoolDistrict,
      setSchoolDistrictValue,
      ageGroup,
      ageItems,
      saveCriteria,
      isSaveCriteria,
      organizationPermissions,
      toggleLink,
      isSharable,
      isSponsorLinkSharable,
      toggleLinkStatus,
      sponsorLinkStatus,
      copy,
      autoSponsoring,
      isGlobalAutoSponsoring,
      sponsorLink,
      shareCodeValue,
      handleCopySponsor,
      regenerateLink
    };
  }
};
