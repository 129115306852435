




















































































































































































































































































































































import { computed, onMounted, ref, Ref, watch } from '@vue/composition-api';
import { User } from '@/generated/graphql';
import { useUserState, useUserActions } from '@/store';
import { fetchTokens } from '../../../api/organizationApi';

export default {
  name: 'PersonalWallet',
  components: {
    Profile: () => import('@/components/Profile.vue'),
    BalanceView: () => import('@/views/Portfolio/BalanceView.vue'),
    SponsorLinksTable: () => import('@/components/portfolio/SponsorLinksTable.vue')
  },

  props: {
    currentWallet: {
      type: Object,
      default: () => {}
    },
    walletList: {
      type: Array,
      default: () => []
    },
    tokens: {
      type: Number,
      required: true
    }
  },

  setup(_props: any, ctx) {
    const transferState = ref(false);
    const sponsorState = ref(false);
    // Token Management
    const originalOwners: Ref<Pick<User, '_id' | 'firstName' | 'lastName'>[]> = ref([]);

    // Create Sponsorship Link Management
    const { getTokens } = useUserActions(['getTokens']);
    const { getTokensBySponsorIdAndUserId, transferTokens } = useUserActions([
      'getTokensBySponsorIdAndUserId',
      'transferTokens'
    ]);
    const { getSponsorLinks } = useUserActions(['getSponsorLinks']);
    const { createSponsorLinks } = useUserActions(['createSponsorLinks']);
    const { revokeSponsorLink } = useUserActions(['revokeSponsorLink']);
    const autoMonitor = ref(true);
    const oneTimeUse = ref(true);
    const sponsorId = ref('');
    const sponsorQuantity = ref();
    const validateQty = ref(0);
    const sponsorLinksTableItems: Ref<any[]> = ref([]);
    const sponsorshipLink = ref('');
    const sponsorWelcomeMessage = ref('');
    const shareLinkDialog = ref(false);
    // Transfer Management
    const transferEmail = ref('');
    const transferQuantity = ref(0);
    const processTransferError = ref(null);
    const { user }: any = useUserState(['user']);
    const loading = ref(false);
    const isLoading = ref(false);
    const isFetching = ref(false);
    const isDeleting = ref(false);
    const isSharing = ref(false);
    const currentCode = ref('');
    const transferSuccess = ref(null);
    const wallet = ref();

    const id = ref(user.value._id.toString());

    const selectedWallet = computed({
      get: () => wallet.value,
      set: newVal => {
        wallet.value = newVal;
      }
    });

    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const organizationPermissions: any = ref(
      localStorage?.getItem('OrganizationPermissions')?.split(',') || []
    );

    async function fetchOrgTokens(val) {
      sponsorQuantity.value = 0;
      transferQuantity.value = 0;
      validateQty.value = 0;
      isFetching.value = true;
      let response;
      if (val?._id) response = await fetchTokens(val._id.toString());
      else if (val?.name?.includes('All')) response = await getTokens();
      // COMMENTING BELOW OUT SO MAXIMUM DOESN'T GET USED
      // sponsorQuantity.value = val?._id ? response?.balance : response?.personalWallet?.balance;
      transferQuantity.value = val?._id ? response?.balance : response?.personalWallet?.balance;
      validateQty.value = val?._id ? response?.balance : response?.personalWallet?.balance;
      isFetching.value = false;
    }

    watch(wallet, val => {
      if (val) {
        fetchOrgTokens(val);
        organizationPermissions.value =
          localStorage?.getItem('OrganizationPermissions')?.split(',') || [];
      }
    });

    watch(_props, val => {
      if (val.currentWallet) {
        wallet.value = val.currentWallet;
      }
    });

    const isValid = computed(() => {
      if (
        sponsorQuantity.value > 0 &&
        selectedWallet.value &&
        sponsorQuantity.value <= validateQty.value
      ) {
        return true;
      }
      return false;
    });

    const updateSponsorQuantity = async () => {
      isFetching.value = true;
      const res = await getTokensBySponsorIdAndUserId({
        owner_id: sponsorId.value
      });
      if (res.data) {
        sponsorQuantity.value = res.data.length;
        isFetching.value = false;
      }
    };

    const formatSponsorLink = (code: string) => `https://www.pilotcity.com/sponsor/${code}`;

    const fetchSponsorLinks = async () => {
      isLoading.value = true;
      const res = await getSponsorLinks();
      if (res) {
        const sponsorLinkData = res.filter(
          sponsorLink =>
            (sponsorLink?.status === 'active' || sponsorLink?.status === 'inactive') &&
            sponsorLink?.type !== 'personal' &&
            sponsorLink?.userId === user?.value?._id?.toString() &&
            sponsorLink?.createdBy === selectedWallet?.value?._id
        );
        sponsorLinksTableItems.value = sponsorLinkData.length ? sponsorLinkData : [];
        fetchOrgTokens(selectedWallet.value);
      }
    };

    watch(selectedWallet, val => {
      if (val) {
        fetchSponsorLinks();
      }
    });

    const sponsorName = item =>
      item.sponsorName ? item.sponsorName : `${item.firstName} ${item.lastName}`;

    const createSponsorshipLink = async () => {
      isSharing.value = true;

      const payload: any = {
        quantity: parseInt(sponsorQuantity.value, 10),
        autoMonitor: autoMonitor.value,
        claim_quota: oneTimeUse.value ? 1 : -1
      };

      if (selectedWallet?.value?._id) {
        payload.id = selectedWallet?.value?._id;
        payload.user_id = user.value?._id?.toString();
        payload.type = 'org';
      } else {
        payload.id = user.value._id.toString();
        payload.type = 'personal';
      }

      const sponsorLinkValue = await createSponsorLinks(payload);
      if (sponsorLinkValue.shareCode) {
        isSharing.value = false;
        shareLinkDialog.value = true;
        sponsorshipLink.value = formatSponsorLink(sponsorLinkValue?.shareCode);
        fetchSponsorLinks();
      }
      sponsorWelcomeMessage.value = `Want to build employer projects to win internships? sponsors you to participate in the PilotCity flagship program. Browse employers using the following sponsorship link & code: ${sponsorshipLink.value}. Welcome aboard! We're rooting for you.`;
      shareLinkDialog.value = true;
      sponsorId.value = '';
      sponsorQuantity.value = 0;
    };

    const revokeToken = async (item: any) => {
      currentCode.value = item.shareCode;
      isDeleting.value = true;
      const payload = {
        id: item.createdBy,
        shareCode: item.shareCode
      };
      const resData = await revokeSponsorLink(payload);
      if (resData) {
        await fetchSponsorLinks();
        isDeleting.value = false;
      }
    };

    const copy = str => {
      navigator.clipboard.writeText(str);
    };

    const processTransfer = async () => {
      loading.value = true;
      try {
        if (transferQuantity.value > validateQty.value) {
          throw new Error('You tried to send more tokens than your account holds.');
        }
        if (transferQuantity.value > 100) {
          throw new Error('Please transfer your tokens in smaller batches (< 100 per batch).');
        }
        const payload: any = {
          recipient_email: transferEmail.value,
          tokens: parseInt(transferQuantity.value, 10)
        };
        if (selectedWallet?.value?._id) {
          payload.id = selectedWallet?.value?._id?.toString();
          payload.type = 'organization';
        } else {
          payload.id = user.value?._id?.toString();
          payload.type = 'personal';
        }
        const res = await transferTokens(payload);

        if (res && res.error) {
          processTransferError.value = res.error.description;
          throw new Error(`${res.error.description}`);
        } else {
          transferSuccess.value = 'Transfer Succeeded';
          loading.value = false;
        }
      } catch (e: any) {
        processTransferError.value = e.message;
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchSponsorLinks();
      fetchOrgTokens(selectedWallet.value);
    });

    watch(sponsorState, val => {
      if (val) {
        fetchSponsorLinks();
        fetchOrgTokens(selectedWallet.value);
      }
    });

    const errors = '';
    const wallets = computed(() => {
      return _props.walletList.filter(w => w.value !== 'personal_wallet');
    });
    return {
      sponsorshipLink,
      selectedWallet,
      isFetching,
      currentCode,
      isSharing,
      isDeleting,
      isLoading,
      transferSuccess,
      isValid,
      sponsorWelcomeMessage,
      dialog6: false,
      shareLinkDialog,
      sponsorQuantity,
      transferState,
      sponsorState,
      originalOwners,
      validateQty,
      autoMonitor,
      oneTimeUse,
      fetchOrgTokens,
      copy,
      sponsorName,
      createSponsorshipLink,
      sponsorLinksTableItems,
      updateSponsorQuantity,
      showCopiedLinkTooltip: ref(false),
      showCopiedMessageTooltip: ref(false),
      revokeToken,
      transferEmail,
      processTransferError,
      loading,
      processTransfer,
      transferQuantity,
      user: useUserState(['user']).user,
      sponsorId,
      errors,
      wallet,
      permissions,
      organizationPermissions,
      fetchSponsorLinks,
      wallets
    };
  }
};
